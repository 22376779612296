import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import CheckboxInput from "../../components/CheckboxInput";

import NotFoundForm from "../../components/NotFoundForm";
import NumberSteper from "../../components/NumberSteper";
import ProductPrice from "../../components/ProductPrice";
import Spinner from "../../components/Spinner";
import { addToCart } from "../../slices/appSlice";
import { singleFetch } from "../../utils/apiRequest";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function AllProductsPage({ cart }) {
  const dispatch = useDispatch();

  const [products, setProducts] = useState(null);
  const [brands, setBrands] = useState([]);
  const [brandFilter, setBrandFilter] = useState([]);

  const query = useQuery();

  useEffect(() => {
    const filterFromParam = query.get("filtro");
    if (filterFromParam) {
      setBrandFilter(JSON.parse(filterFromParam));
    }
  }, [query]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const request = await singleFetch(
        `producto-detalles?filter={
          "offset": 0,
          "limit": 5000,
          "skip": 0,
          "order": ["marca asc", "nombreTienda asc", "precioVenta asc"]
        }`
      );
      const products = await request.json();
      setProducts(products);

      const brandsRequest = await singleFetch(
        'marcas?filter={"offset": 0,"limit": 300,"skip": 0,"order": ["nombre asc"]}'
      );
      const brandsData = await brandsRequest.json();
      setBrands(brandsData);

      const filterFromParam = query.get("filtro");
      if (filterFromParam) {
        setBrandFilter(JSON.parse(filterFromParam));
      } else {
        setBrandFilter(["all"].concat(_.map(brandsData, "id")));
      }
    };

    fetchData();
  }, [query]);

  return (
    <div>
      {!products ? (
        <Spinner />
      ) : (
        <div className="flex flex-wrap lg:flex-nowrap w-full px-16 pt-4 pb-16 min-h-[400px]">
          <div className="w-full flex flex-wrap lg:block lg:flex-nowrap lg:w-3/12 mb-4">
            <p className="mb-4 w-full">Marcas</p>
            <CheckboxInput
              containerClass={"mb-2 ml-2 lg:ml-0"}
              checked={brandFilter.includes("all")}
              onCheckChange={() => {
                const brandIndex = brandFilter.indexOf("all");
                if (brandIndex === -1) {
                  brandFilter.push("all");
                  setBrandFilter(["all"].concat(_.map(brands, "id")));
                } else {
                  setBrandFilter([]);
                }
              }}
              labelColor="text-base"
              label={"Todas las marcas"}
            />
            {brands.map((b, index) => {
              const isChecked = brandFilter.indexOf(b.id) !== -1;
              return (
                <CheckboxInput
                  key={`filter_${index}`}
                  containerClass={"mb-2 ml-2 lg:ml-0"}
                  checked={isChecked}
                  onCheckChange={() => {
                    const newBrandFilter = _.clone(brandFilter);
                    if (!isChecked) {
                      newBrandFilter.push(b.id);
                      setBrandFilter(newBrandFilter);
                    } else {
                      const brandIndex = newBrandFilter.indexOf(b.id);
                      newBrandFilter.splice(brandIndex, 1);
                      const allIndex = newBrandFilter.indexOf("all");
                      if (allIndex !== -1) {
                        newBrandFilter.splice(allIndex, 1);
                      }
                      setBrandFilter(newBrandFilter);
                    }
                  }}
                  labelColor="text-base"
                  label={b.nombreTienda}
                />
              );
            })}
          </div>
          <div className="w-full lg:w-9/12">
            {products && (
              <div className="flex flex-wrap gap-2">
                {products.map((p) => {
                  if (brandFilter.indexOf("all") === -1) {
                    if (brandFilter.indexOf(p.idMarca) === -1) {
                      return null;
                    }
                  }
                  return (
                    <div
                      key={`producto_${p.id}`}
                      className={`flex flex-wrap flex-[0_0_calc(50%-0.5rem)] sm:flex-[0_0_calc(50%-0.5rem)] md:flex-[0_0_calc(50%-0.5rem)] lg:flex-[0_0_calc(25%-0.5rem)] items-center bg-backgroundGray py-4 px-8 rounded-md`}
                    >
                      {p.fotoPrincipal ? (
                        <Link
                          to={`/tienda/producto/${
                            p.id
                          }?categoria=todos&filtro=${JSON.stringify(
                            brandFilter
                          )}`}
                        >
                          <img
                            className="cursor-pointer rounded-md"
                            src={p.fotoPrincipal}
                            alt={`Foto de ${p.nombreTienda}`}
                          />
                        </Link>
                      ) : (
                        <p className="text-listingProductGray font-bold">
                          Fotografía no disponible
                        </p>
                      )}
                      <Link
                        to={`/tienda/producto/${
                          p.id
                        }?categoria=todos&filtro=${JSON.stringify(
                          brandFilter
                        )}`}
                      >
                        <p className="cursor-pointer w-full text-sm text-listingProductGray mt-2 font-bold">
                          {p.marca} {p.nombreTienda}
                        </p>
                      </Link>
                      {p.pesoGramos && (
                        <p className="w-full text-sm text-listingProductGray mt-2">
                          {"Presentación: "}
                          <br />
                          <span className="font-bold">
                            {p.pesoGramos < 1000
                              ? `${p.pesoGramos} gramos`
                              : `${(p.pesoGramos / 1000).toFixed(2)} Kg`}
                          </span>
                        </p>
                      )}
                      <ProductPrice hideDiscountText product={p} />
                      {p.calcularFechaFinalConsumo && (
                        <div className="mt-4 w-full">
                          <p className="text-listingProductGray text-lg">
                            {new Intl.NumberFormat("es-CR", {
                              style: "currency",
                              currency: "CRC",
                            }).format(
                              (p.precioVenta * (p.descuento || 1)) /
                                (p.pesoGramos / 1000)
                            )}
                          </p>
                          <span className="text-listingProductGray text-xs">
                            {" "}
                            por kilo
                          </span>
                        </div>
                      )}
                      <p className="w-full text-sm text-listingProductGray mt-2">
                        {"Stock: "}
                        <br />
                        <span className="font-bold">{p.cantidadEnBodega}</span>
                      </p>
                      <p className="w-full text-sm text-listingProductGray mt-2">
                        {"Unidades disponibles en precio de descuento: "}
                        <br />
                        <span className="font-bold">
                          {p.unidadesDisponiblesDescuento === null
                            ? "Hasta agotar existencias"
                            : p.unidadesDisponiblesDescuento}
                        </span>
                      </p>
                      <p className="w-full text-sm text-listingProductGray my-2">
                        {"Código de proveedor"}
                        <br />
                        <span className="font-bold">{p.codigoProveedor}</span>
                      </p>
                      <div>
                        <NumberSteper
                          label="Cantidad:"
                          labelColor="text-base"
                          value={
                            cart.products[parseInt(p.id)]
                              ? cart.products[parseInt(p.id)].quantity
                              : 0
                          }
                          onChangeValue={(value) => {
                            if (
                              value < 0 &&
                              (!cart.products[parseInt(p.id)] ||
                                !cart.products[parseInt(p.id)].quantity ||
                                cart.products[parseInt(p.id)].quantity === 0)
                            ) {
                              return;
                            }
                            dispatch(
                              addToCart({
                                product: p,
                                quantity: cart.products[parseInt(p.id)]
                                  ? cart.products[parseInt(p.id)].quantity
                                    ? cart.products[parseInt(p.id)].quantity +
                                      value
                                    : value
                                  : value,
                                dailyConsumption: 0,
                                whatsappConfirmation: false,
                                estimatedOutOfFoodDate: "",
                              })
                            );
                          }}
                          negativeStepSize={-1}
                          positiveStepSize={1}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
      <NotFoundForm />
    </div>
  );
}

export default AllProductsPage;
